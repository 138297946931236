<template>
  <div>
    <list-kid class="kids" :kids="getKids"/>
    <div class="files-container">
      <div class="files-header">
        <h2>Fichiers</h2>
        <span class="button-size"></span>
      </div>
      <div class="files">
        <a
          v-for="file in files"
          :key="file.uid"
          :href="file.url"
          target="_blank"
        >
          <font-awesome-icon :icon="['far', 'file']" />
          <span>{{ file.displayname }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListKid from '@/components/ListKid.vue'

export default {
  name: 'Dashboard',
  components: {
    ListKid
  },
  data() {
    return {
      kid: null,
    }
  },
  computed: {
    ...mapGetters('kids', ['getKids']),
    ...mapGetters('files', ['getFiles']),
    files() {
      return this.getFiles.filter(file => file.visibility)
    }
  },
  methods: {
    ...mapActions('kids', ['fetchUserKids']),
  },
  created() {
    this.fetchUserKids()
  }
}
</script>

<style lang="scss" scoped>
.kids {
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
}

div.files-container {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;

  div.files-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;

    h2 {
      font-size: 20px;
    }

    .button-size {
      height: 36px;
    }
  }

  div.files {
    display: flex;
    flex-direction: column;
    gap: 15px;

    a {
      width: fit-content;

      svg {
        margin-right: 10px;
      }
    }
  }
}
</style>